import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/layout/seo"

import MainImg from "../../img/whereSC.jpg"

function Escalator() {
  return (
    <Layout>
      <SEO title="M2MTech" />
      <div className="fobWrap escalWrap">
        <p className="fobMainImg escalMainImg">
          <img src={MainImg} alt="" />
        </p>
        <div className="sectionWrap fobSectionWrap escalOverview">
          <div className="textWrap">
            <h2>에스컬레이터&amp;무빙워크</h2>
            <p className="bodyText">
              에스컬레이터와 무빙워크는 단절된 공간을 이어주고 빠른 이동을
              도와주는 승강기의 발전된 형태로서 대형 상업건물, 공항, 항만 등
              이동인구가 많고 동선과 이동 시간을 최소화 할때 최대의 효율과
              이익을 낼수있는 공간에서 주로 사용됩니다. 이러한 대형 공간에서
              에스컬레이터 고장이나 잦은 사고는 공간에 대한 사용자경험을
              부정적으로 인식시킬 뿐 만 아니라 유입자의 피곤을 극대화 해 매출,
              이익등을 저해할 여지가 있습니다. ELSA Platform은 이러한 대형
              기기의 실시간 감시 및 제어기능, 부품 고장 즉시 중앙 상황실 뿐만
              아니라 엔지니어의 각 모바일 기기에 알림을 전송해 기기 에러를
              원격으로 진단, 처리하고 시속한 출동과 문제 해결이 가능합니다.
              <br />
              <br />
              승강기 및 에스컬레이터 제어시스템 연구개발 회사인 세라S.E. 의 40
              여년의 경험과 엠투엠테크의 신기술을 적용한 협업을 통해 ELSA 의
              유지관리 시스템을 적용한 현장을 늘려가고 있습니다. 부산의
              롯데백화점 세텀시티점, 건대 롯데스타시티점, 분당 롯데백화점 등의
              사이트에서 사용하는 ELSA Platform은 다양한 기능들을 제공하고
              유지관리 비용 감소와 업무 효율을 높여주는 장점이 증명되었습니다.
            </p>
          </div>
        </div>
      </div>
    </Layout>
  )
}
export default Escalator
